<template>
  <visualizar-mapa />
</template>

<script>
export default {
  name: 'DashboardPadrao',
  components: {
    VisualizarMapa: () =>
      import('@/views/entidades-geo-bdgd/VisualizarMapa.vue')
  }
};
</script>
